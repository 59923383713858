.gallery45{
    display: flex;
    flex-direction: row;
    
    justify-content: center;
    align-items: center;
}

.gallery1{
    display: flex;
   padding: 40px;
}

.gallery1 img{
    width: 320px;
    height: 290px;
    border-radius: 10px;
}



.gallery2 h1{
    display: flex;
    justify-content: center;

}